(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'jquery'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('jquery'));
    } else {
        // Browser globals
        factory((root.commonJsStrict = {}), root.jQuery);
    }
}(this, function (exports, $) {

    function smoothScroll($target, cb) {
        var scrollTop = $target.offset().top;
        $('html, body').animate({ scrollTop: scrollTop }, 300, function () {
            if(cb) {
                cb();
            }
        });
    }

    // $topMenu.on('click', '.smooth-scroll', function (e) {
    //     var hash = this.hash;
    //     var $target = $(hash);
    //     if ($target.length) {
    //         smoothScroll($target, function () {
    //             location.hash = hash;
    //         });
    //     }
    // });

    var count = 1;
    $(".blog-post-next a").click(function(e) {
        var $self = $(this);
        var $parent = $self.parent();
        $parent.addClass('busy');
        e.preventDefault();

        $.ajax({
            type: "POST",
            url: JS_DATA.ajax_url,
            dataType: 'html',
            data: ({
                action: 'ktc_blog_grid',
                offset: count * JS_DATA.posts_per_page,
                posts_per_page: JS_DATA.posts_per_page
            }),
            success: function(html) {
                if(html === '-1') {
                    $self.text('No more posts!');
                } else {
                    $('.blog-grid-wrap').append(html);
                }
                count++;
            },
            complete: function () {
                $parent.removeClass('busy');
            }

        });
    });

}));

